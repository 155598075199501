import Vue from 'vue';
import 'vant/lib/index.css';

import {
  Button,
  Tabbar,
  TabbarItem,
  NavBar,
  Search,
  Form,
  Picker,
  Field,
  Popup,
  Tab,
  Tabs,
  Cell,
  CellGroup,
  Switch,
  Empty,
  Dialog,
  Badge,
  DatetimePicker,
  List,
  Loading,
  Calendar
} from 'vant';
import {Image as VanImage} from 'vant';


Vue.use(Button).use(Tabbar).use(TabbarItem).use(NavBar).use(Search).use(Form).use(Picker).use(Field).use(Popup).use(Tab)
  .use(Tabs).use(Cell).use(CellGroup).use(Switch).use(VanImage).use(Empty).use(Dialog).use(Badge).use(DatetimePicker)
  .use(List).use(Loading).use(Calendar)
