import Vue from 'vue'
import App from './App.vue'
import '@/components/vant/index'
import router from './router'
import FastClick from 'fastclick'

const str = navigator.userAgent.toLowerCase()
const ver = str.match(/cpu iphone os (.*?) like mac os/)
console.log(ver)

if (!ver) { // 非IOS系统
  // 引入fastclick 做相关处理
  FastClick.attach(document.body)
} else {
  if (parseInt(ver[1]) < 11) {
    // 引入fastclick 做相关处理
    FastClick.attach(document.body)
  }
}

//判断是否是微信浏览器的函数

if(str.match(/MicroMessenger/i) == 'micromessenger'){
  console.log('1')
}


// 顶部 title
import vueWechatTitle from "vue-wechat-title";
Vue.use(vueWechatTitle);

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
