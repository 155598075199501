<template>
  <div id="app">
    <router-view v-wechat-title="$route.meta.title"/>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
html,body{
  height: 100%;
}
#app{
  height: 100%;
  background: #f3f3f3;
}
</style>
