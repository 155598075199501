import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

let routes = [
    {
        path: '/login',
        name: '登录',
        component: () => import('@/views/login/index'),
        meta: {title: '登录'}
    },
    {
        path: '/',
        name: '入口',
        component: () => import('@/views/login/index'),
        redirect: '/content/total',
    },
    {
        path: '/chart',
        name: 'chart',
        component: () => import('@/views/chart/index'),
        meta: {title: '数据'}
    },
    {
        path: '/auth',
        name: 'auth',
        component: () => import('@/views/auth/index'),
        meta: {title: '授权'}
    },
    {
        path: '/personnel',
        name: 'personnel',
        component: () => import('@/views/personnel/index'),
        meta: {title: '人员查询'}
    },
    {
        path: '/content',
        component: () => import('@/views/layout/index'),
        meta: {title: ''},
        children: [
            {
                path: 'total',
                name: '总览',
                component: () => import('@/views/total/index'),
                meta: {title: '数据总览'}
            },
            {
                path: 'monitor',
                name: '监控',
                component: () => import('@/views/monitor/index'),
                meta: {title: '监控'}
            },
            {
                path: 'device',
                name: 'device',
                component: () => import('@/views/device/index'),
                meta: {title: '设备详情'}
            },
            {
                path: 'chartExplore',
                name: 'chartExplore',
                component: () => import('@/views/chartExplore/index'),
                meta: {title: '数据探索'}
            },
            {
                path: 'fault',
                name: 'fault',
                component: () => import('@/views/faultDetails/index'),
                meta: {title: '故障详情'}
            },
            {
                path: 'my',
                name: 'my',
                component: () => import('@/views/my/index'),
                meta: {title: '我的'}
            },
            {
                path: 'personnel',
                name: 'personnel',
                component: () => import('@/views/personnel/index'),
                meta: {title: '人员查询'}
            }
        ]
    },
]

const router = new Router({
    routes,
    scrollBehavior(to, from, savedPosition) {
        console.log(to, from, savedPosition)
        if (to.hash) {
            return {
                selector: to.hash
            }
        }
    }
})

router.beforeEach((to, from, next) => {

    // 去登陆页面
    if (to.fullPath === '/login') {
        next()
    }
    // 去其他页面检查token是否存在，不存在则去登录页面，存在则继续
    else {
        let token = localStorage.getItem('token');
        console.log(token)
        if (!token) {
            next('/login')
        } else {
            next()
        }
    }
})

export default router
